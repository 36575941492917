import {
  Box,
  Button,
  CircularProgress,
  Grid,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import { isEqual } from "lodash";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";

import { getCropTypesList } from "../../../../modules/crop-types";
import { setFilterAction, useDisableGlobalFilters } from "../../../../modules/filter";
import { FilterName } from "../../../../modules/filter/shared/enums/filter-name";
import { getFilter } from "../../../../modules/filter/store/filters.selector";
import {
  getInfoTechOperationGroups,
  getInfoTechOperationSubGroups,
} from "../../../../modules/info-data";
import { RHFTextField } from "../../../../shared/components/react-hook-form-mui/textfield";
import { findModelByProperty } from "../../../../shared/utils/get-collection-item-by-field";
import { useAppDispatch } from "../../../../store";
import { IFarmLandDto, formatFarmLand } from "../../../fields/shared/dtos/farm-land.dto";
import { TechOperationEditingEmployees } from "../../components/tech-operation-editing-employees/tech-operation-editing-employees";
import { TechOperationEditingSubgroupParams } from "../../components/tech-operation-editing-subgroup-params/tech-operation-editing-subgroup-params";
import { TechOperationEditingTechAssets } from "../../components/tech-operation-editing-techassets/tech-operation-editing-techassets";
import {
  ITechOperationFormData,
  emptyFormTechOp,
} from "../../shared/interfaces/tech-operation-form-data";
import { TechOperation } from "../../shared/models/tech-operation";
import { TechOperationEditingAssets } from "../tech-operation-editing-assets/tech-operation-editing-assets";
import { TechOpEditDates } from "./techop-edit-dates";
import { OperationNumber } from "../../../../shared/dtos/techop-edit-types";
import { isEditingPage } from "../../../../shared/utils/is-editing-page";
import {
  ITechOperationDto,
  isOriginalDto,
  isPredecessorDto,
} from "../../shared/dtos/tech-operation.dto";
import { PATHS, TECHOPID_NEW_KEYWORD } from "../../../../constant";
import { SpinnerCenter } from "../../../../shared/components/SpinnerCenter";
import { ScreenCenter } from "../../../../shared/components/ScreenCenter";
import { useLocation, useNavigate } from "react-router-dom";
import { RHFAutocomplete } from "../../../../shared/components/react-hook-form-mui/autocomplete";
import { TechopHistory } from "./techop-history";
import { ITechOperationGroupDto } from "../../shared/dtos/tech-operation-group.dto";
import { ITechOperationSubGroupDto } from "../../shared/dtos/tech-operation-sub-group.dto";
// import { useCurrentFarmLands } from "../../../../modules/farm-lands/current-farm-lands.hook";
// import {
//   getCurrentFarmLands_includeDeleted,
//   getLoadingCurrentFarmLands,
// } from "../../../../modules/farm-lands/current-farm-lands.selector";
import { useCurrentFarmLands_RtkQ } from "../../../../modules/farm-lands/current-farm-lands.hook.rtkq";
import { RHFAutocompleteSimple } from "../../../../shared/components/react-hook-form-mui/autocomplete-simple";
import { farmLandFormatCombined } from "../../shared/utils/farm-land-format";
import {
  TechOpListRequest,
  TechOpListRequestor,
  useAreaHistoryRequestor,
} from "./use-area-history-requestor";
import {
  AreaHistory,
  AreaHistoryInput,
  useAreaHistoryCalculator,
} from "./use-area-history-calculator";
import { OPERATION_NUMBER_NEW, RhfTechOpEditFormUtils } from "./use-form-utils";
import {
  useFetchTechOpQueryState,
  // useFetchTechOpQuery,
  useLazyFetchTechOpQuery,
} from "../../store/techop.rtkq";
import { FetchOperationNumbers_forGroupRequest } from "../../store/techop-related.rtkq";
import {
  useLazyFetchSubGroupIds_forCropTypeQuery,
  useLazyFetchOpNumbers_forGroupQuery,
} from "../../store/techop-related.rtkq";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { devlog, devwarn } from "../../../../shared/utils/log";
import {
  useFetchAssetTypes_forTechOpQuery,
  useFetchTechAssets_forTechOpIdQuery,
} from "../../../../modules/tech-assets/store/techop-assets.rtkq";
import { colorifyError, useAutoHide } from "../../../../shared/hooks/autohide";
import { asLoopbackError } from "../../../../modules/tech-assets/store/techop-assets.rtkq.types";
import {
  TechOperationAgregatedsV2Error,
  asTechOperationAgregatedsV2Error,
} from "../../store/techop.rtkq.types";
import { useSeasons } from "../../../../shared/hooks/seasons";
import { TechOpsTestIds } from "../../shared/types/tech-operation-data-test";

export const TechOpEditFormContent = ({
  techOpId,
  rhfSingleton,
  setDisabled, // affects parent form's SAVE button, controlled from here
  farmLandId,
}: {
  techOpId?: string;
  // singleAvailableFarmLandName?: string;
  rhfSingleton: RhfTechOpEditFormUtils;
  setDisabled?: React.Dispatch<React.SetStateAction<boolean>>;
  farmLandId?: string;
}): React.JSX.Element => {
  const { search } = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const query = new URLSearchParams(search);
  const farmLandId_fromUrl = query.get("farmLandId");
  const techOpGroup_fromUrl = query.get("techOperationGroupId");
  const techOpSubGroupId_fromUrl = query.get("techOperationSubGroupId");
  const opNo_fromUrl = query.get("operationNumber");

  const { isFetching: isFetchingTechOp } = useFetchTechOpQueryState(skipToken);

  const appFilterFarmId =
    useSelector(getFilter(FilterName.FarmId)) ?? "SELECT_FARM_FROM_UPPER_DROPDOWN";
  // const appFilterSeasonId =
  //   useSelector(getFilter(FilterName.SeasonId)) ?? "SELECT_SEASON_FROM_UPPER_DROPDOWN";

  // requested & updated by the child component <TechOpEditDates />
  // const [fieldSizeAvailable, setFieldSizeAvailable] = useState(0);

  const {
    appFilterSeasonId,
    appFilterSeasonNameResolved,
    seasonDates,
    allSeasonsSorted,
    setSeasonDatesForSeasonId,
  } = useSeasons();

  const techOperationsGroups: ITechOperationGroupDto[] = useSelector(
    getInfoTechOperationGroups
  );
  const techOperationsSubGroups: ITechOperationSubGroupDto[] = useSelector(
    getInfoTechOperationSubGroups
  );
  const cropTypes = useSelector(getCropTypesList);

  const { isLoading: isLoadingAssetTypes } = useFetchAssetTypes_forTechOpQuery();

  const skipAssets = useMemo(
    () => techOpId === undefined || techOpId === TECHOPID_NEW_KEYWORD,
    [techOpId]
  );

  const assetsQuery = useFetchTechAssets_forTechOpIdQuery(techOpId || "", {
    skip:
      // WEIRD_MEMOZED_REQUIRED
      // techOpId === undefined || techOpId === TECHOPID_NEW_KEYWORD,
      skipAssets,
  });
  const {
    isLoading: isLoadingTechOpAssets,
    data: techOpAssets,
    // error: errorFetchOpNumbers_forGroup,
  } = assetsQuery;

  // requested & updated by the child component <TechOpEditSubgroup />
  const [subGroupIds_forCropType, setSubGroupIds_forCropType] = useState<
    string[] | undefined
  >(undefined); // NOT_YET_LOADED if undefined

  const {
    isLoadingFarmLands,
    // farmLands_includeDeleted: farmLands,
    // if a TO was created on a deleted farmField,
    // dropdown will have an empty value => SAVE will ERASE the farmField
    // (SAVE should be disabled until a field is selected)
    farmLands_nonDeleted: farmLands,
  } = useCurrentFarmLands_RtkQ();

  // requested & updated by the child component <TechOpEditOperationNumber />
  const [availableOperationNumbers, setAvailableOperationNumbers] = useState<
    OperationNumber[]
  >([]);

  const [techOpFetched, setTechOpFetched] = useState<TechOperation | undefined>(
    undefined
  );

  // const { data: techOpFetched, error: errorFetchOp } = useFetchTechOpQuery(techOpId, {
  //   skip: techOpId === undefined,
  // });

  const [
    fetchTechOp_trigger,
    {
      // data: techOpFetched,
      error: errorFetchOp_unknown,
    },
  ] = useLazyFetchTechOpQuery();

  // mimic'ing transformAgregatedsV2ErrorResponse return type
  const errorFetchOp = errorFetchOp_unknown as string | TechOperationAgregatedsV2Error;

  const {
    // sourceFarmLandId,
    resetFieldsBelowSubGroup,
    defaultValues,
    setDefaultValues,
    rhfMethods,
    assetsKeyToResetRhfAssetsArray,
    absorbTechOp_toDefaultValues,
    operationNumber: opno_fromForm,
    nextOperationNumber,
    setNextOperationNumber,
    // hasSubGroupId,
    cropId,
    setCropIdWrapper,
    renderAfterControllerFilled,
    setRenderAfterControllerFilled,
  } = rhfSingleton;
  const { setValue, reset, trigger } = rhfMethods;
  const watchFields = rhfMethods.watch();

  const hasSubGroupId = useMemo(
    () => techOpFetched?.techOperationSubGroupId !== undefined,
    [techOpFetched]
  );

  // Utility getters

  const isExistingTechOperation = useMemo(() => isEditingPage(techOpId), [techOpId]);

  useEffect(() => {
    if (isExistingTechOperation === false) {
      // user clicked on onAddNewTechOp() button
      setTechOpFetched(undefined);
      // setDefaultValues(getDefaultValues_forCurrentFarmLand());
      // onAddNewTechOp() will invoke resetFieldsBelowSubGroup()
    }
  }, [isExistingTechOperation]);

  const setPartialDefaultValue = useCallback(
    (partialToFlush: Partial<ITechOperationFormData>) => {
      setDefaultValues((prevValues: ITechOperationFormData) => {
        const merged = mergeFormData(prevValues, partialToFlush);
        return merged;
      });
    },
    [setDefaultValues]
  );
  const setDefaultFarmAndSeason = useCallback(() => {
    if (!appFilterFarmId || !appFilterSeasonId) {
      return;
    }
    const newDefaults: Partial<ITechOperationFormData> = {
      startDate: null,
      finishDate: null,
      farmId: appFilterFarmId.toString(), // from develop
      seasonId: appFilterSeasonId.toString(), // from develop
    };
    if (farmLandId !== undefined) {
      // map => farmLand => techOps => addNewTechop, tracktor => techOps => edit => ДОБАВИТЬ
      newDefaults.farmLandId = farmLandId;
    }
    setPartialDefaultValue(newDefaults);
  }, [appFilterFarmId, appFilterSeasonId, farmLandId, setPartialDefaultValue]);

  useEffect(() => {
    if (watchFields.farmId !== "" && appFilterFarmId?.toString() !== watchFields.farmId) {
      // upper FARM dropdown is reset to whatever techOp.farmId
      // (fixing backend bug when a new TO is assigned to another farm / field)
      dispatch(setFilterAction({ filter: FilterName.FarmId, value: watchFields.farmId }));
    }
  }, [dispatch, appFilterFarmId, watchFields.farmId]);

  useEffect(() => {
    if (techOpAssets === undefined) {
      return; // WAITING_UNTIL_fetchAssetsByTechOperationIdAction_COMPLETES
    }
    if (techOpAssets.length === 0) {
      return;
    }

    // existing techop received assets from fetchAssets_forTechOperationIdAction(techOpId)
    // empty assetsFetched will additionally reset ТМЦ (RHFv6 dynamic fields misbehave)
    const assetsFetched = techOpAssets.map((item) => item.asFormData);
    setDefaultValues((data) => ({
      ...data,
      assets: assetsFetched,
    }));
  }, [techOpAssets, setDefaultValues]);

  // пожалуйста не стирайте тип
  const farmLand_fromTechOp_orUrl: IFarmLandDto | undefined = useMemo(() => {
    if (!farmLands) {
      return undefined; // пожалуйста возвращайте типизированое значение
    }
    if (farmLands.length === 0) {
      return undefined; // пожалуйста возвращайте типизированое значение
    }
    if (
      watchFields.farmLandId === null || // anyone can change type to "farmLandId?: string | null" and forget to check everywhere
      watchFields.farmLandId === undefined ||
      watchFields.farmLandId === ""
    ) {
      return undefined; // пожалуйста возвращайте типизированое значение
    }
    const ret = farmLands.find((x) => x.id === watchFields.farmLandId);
    return ret; // две строки чтобы поставить брейкпойнт (обозначаю как INSERT_BREAKPOINT)
  }, [
    farmLands,
    // watchFields.farmLandId changes when:
    // 1) FARM selector gets loaded
    // 2) "Добавить в №3" => F5
    // 3) by DIRECT LINK sent via messenger to "Добавить в №3" page
    // 4) techop-list.tsx, выбрать поле, "Добавить тех.оп" => useTechOpFormUtils() =>
    //    emptyFormTechOp(appFilterFarmId, appFilterSeasonId, farmLandId_fromUrl)
    watchFields.farmLandId,
  ]);

  useEffect(() => {
    // ADD => select FIELD => select ANOTHER FIELD => reset everything below
    if (isExistingTechOperation) {
      return; // never reset data we received from the backend
    }
    if (farmLand_fromTechOp_orUrl === undefined) {
      return;
    }
    if (opNo_fromUrl !== null) {
      // clicked on "Добавить в №3" button; don't reset upper 4 fields
      // on DIRECT LINK to "Добавить в  №3", we'll setValue("cropTypeId") in prepareFieldsForSameNumberAdd()
      return;
    }
    setValue("operationNumber", "");
    setValue("techOperationGroupId", "");
    setValue("techOperationSubGroupId", "");
    // on DIRECT LINK to "Добавить в  №3", we'll setValue("cropTypeId") in prepareFieldsForSameNumberAdd()
    setValue("cropTypeId", farmLand_fromTechOp_orUrl.cropTypeId);

    // farmLands loaded => saving currentFarmLand's cropId
    setCropIdWrapper(farmLand_fromTechOp_orUrl.cropId);
  }, [
    isExistingTechOperation,
    farmLand_fromTechOp_orUrl,
    opNo_fromUrl,
    setValue,
    setCropIdWrapper,
  ]);

  const [fetchSubGroups_forCropType_trigger] = useLazyFetchSubGroupIds_forCropTypeQuery();
  const fetchSubGroups_forCropType = useCallback(
    (cropTypeId: string) => {
      fetchSubGroups_forCropType_trigger(cropTypeId)
        .unwrap()
        .then((subGroupIdsFetched) => {
          setSubGroupIds_forCropType(subGroupIdsFetched);
        })
        .catch((ex) => {
          devlog(`fetchSubGroups_forCropType_trigger(): EXCEPTION`, ex);
          devlog(`AVOIDING_/static/js/4.chunk.js on backend 500 InternalServerError`);
        });
    },
    [fetchSubGroups_forCropType_trigger]
  );

  useEffect(() => {
    if (!watchFields.cropTypeId) {
      return;
    }
    fetchSubGroups_forCropType(watchFields.cropTypeId);
  }, [fetchSubGroups_forCropType, watchFields.cropTypeId]);

  const availableTechOperationsSubGroups = useMemo(() => {
    if (
      watchFields.techOperationGroupId === null ||
      watchFields.techOperationGroupId === undefined ||
      watchFields.techOperationGroupId === ""
    ) {
      return [];
    }

    const ret = techOperationsSubGroups.filter(
      (subGroup_forTechOp) =>
        subGroup_forTechOp.parentId === watchFields.techOperationGroupId
    );

    if (subGroupIds_forCropType === undefined || subGroupIds_forCropType.length === 0) {
      return ret;
    }

    const filteredForCropType = ret.filter((x) => subGroupIds_forCropType.includes(x.id));
    if (filteredForCropType.length > 0) {
      return filteredForCropType;
    }

    return ret;
  }, [
    techOperationsSubGroups,
    subGroupIds_forCropType,
    watchFields.techOperationGroupId,
  ]);

  const [subGroupIdByOpNo, setSubGroupIdByOpNo] =
    useState<Map<number, ITechOperationDto>>(); // undefined for NOT_YET_FETCHED

  const areaHistory_forOpNumberRequest: TechOpListRequest = useMemo(() => {
    const finalFarmLandId: string =
      techOpFetched?.farmLandId ||
      farmLandId_fromUrl ||
      watchFields.farmLandId || // /tech-operations/editing/new => selected in dropdown
      "FARM_LAND_ID_NOT_SET";

    return {
      farmLandId: finalFarmLandId,
      Real_farmLandId: techOpFetched?.Real_farmLandId,
      techOperationGroupId:
        watchFields.techOperationGroupId || (techOpGroup_fromUrl ?? undefined),
      operationNumber: opno_fromForm,
    };
  }, [
    techOpFetched,
    farmLandId_fromUrl,
    techOpGroup_fromUrl,
    watchFields.farmLandId,
    // Real_farmLandId,
    watchFields.techOperationGroupId,
    opno_fromForm,
  ]);

  const requestor: TechOpListRequestor = useAreaHistoryRequestor(
    areaHistory_forOpNumberRequest
  );

  const {
    // isLoading,
    isFetching: isLoadingAreaHistory,
    fetchedAreaHistory_forOpNo,
  } = requestor;

  const fieldSizeTotal_fromFarmLands: number | undefined = useMemo(() => {
    if (!farmLand_fromTechOp_orUrl) {
      return undefined; // INSERT_BREAKPOINT
    }
    if (!farmLand_fromTechOp_orUrl.area) {
      return undefined; // INSERT_BREAKPOINT
    }
    return farmLand_fromTechOp_orUrl.area;
  }, [farmLand_fromTechOp_orUrl]);

  const areaHistoryCalculatorInput: AreaHistoryInput = useMemo(
    () => ({
      isLoadingAreaHistory,
      fetchedAreaHistory_forOpNo,
      seasonId: appFilterSeasonId ?? "SELECT_SEASON_FROM_UPPER_DROPDOWN",
      farmLandFromTechOp: farmLand_fromTechOp_orUrl,
      subGroupId:
        watchFields.techOperationSubGroupId ||
        (techOpSubGroupId_fromUrl ?? "NO_SUBGROUP"),
      editingThisTechOpId: techOpId,
      fieldMax: fieldSizeTotal_fromFarmLands || 0,
      techOperationsSubGroups,
    }),
    [
      fetchedAreaHistory_forOpNo,
      appFilterSeasonId,
      watchFields.techOperationSubGroupId,
      techOpId,
      fieldSizeTotal_fromFarmLands,
      techOperationsSubGroups,
      farmLand_fromTechOp_orUrl,
      isLoadingAreaHistory,
      techOpSubGroupId_fromUrl,
    ]
  );

  const areaHistory: AreaHistory = useAreaHistoryCalculator(areaHistoryCalculatorInput);
  const {
    agroWorksForOpNoForSeason,
    areaProcessedHistory,
    remainderForThisOpNumberAndSubGroup,
    clearAreaHistory,
  } = areaHistory;

  // const fieldSizeTotal_fromFarmLandsRef = useCallback(
  //   async (farmLandId: string): Promise<number | undefined> => {
  //     let myFarmLands = farmLands;
  //     if (!myFarmLands) {
  //       // after loadFarmLands() finished, setFarmLands() has already been invoked;
  //       // but re-render didn't happen yet;
  //       // loadFarmLands() returns its own farmLandsRef here:
  //       myFarmLands = await loadFarmLands();
  //     }
  //     if (!myFarmLands) {
  //       return 0;
  //     }

  //     const farmLandFound = myFarmLands.find((x) => x.id === farmLandId);
  //     if (!farmLandFound) {
  //       return 0;
  //     }

  //     const fieldSizeTotal = farmLandFound.area ?? undefined;
  //     return fieldSizeTotal;
  //   },
  //   [farmLands, loadFarmLands]
  // );

  // const techOpsForOpNumFetched = useMemo(
  //   () => agroWorksForOpNoForSeason,
  //   [agroWorksForOpNoForSeason]
  // );

  // useEffect(async () => {
  //   let fieldMax = fieldSizeTotal_fromFarmLands;

  //   let overwriteFieldMax_fromPredecessor =
  //     farmLandId !== Real_farmLandId ? Real_farmLandId : undefined;

  //   // seems like backend returns for current techop farmLandId === Real_farmLandId
  //   // https://release.ekocrop.ekoniva-apk.dev/tech-operations/editing/BA6429A7-AE01-4556-A64E-0055C3507784
  //   // в "Поле": "36.09.07.029.02 Пар 3га"
  //   // в "номере техоп": №4 культивация на предшественнике 36.09.07.029.00 Сахарная свёкла 145.34га
  //   // поэтому вытаскиваем fieldMax из 36.09.07.029.00 Сахарная свёкла 145.34га
  //   // на local: http://localhost:3000/tech-operations/editing/34F48101-723F-449A-B195-0E76A3EBF108
  //   const farmLandForOpNo = subGroupIdByOpNo?.get(forOpNumber);
  //   if (farmLandForOpNo) {
  //     const isOpnoOnPredecessor = farmLandForOpNo.farmLandId !== farmLandId;
  //     if (isOpnoOnPredecessor) {
  //       overwriteFieldMax_fromPredecessor = farmLandForOpNo.farmLandId;

  //       const formatted = farmLandFormatCombined(
  //         overwriteFieldMax_fromPredecessor,
  //         farmLands?.find((x) => x.id === overwriteFieldMax_fromPredecessor)
  //       );

  //       devlog(
  //         `overwriteFieldMax_fromPredecessor[${formatted}] fieldSize[${farmLandForOpNo.fieldSize}]`
  //       );
  //     }
  //   }

  //   if (overwriteFieldMax_fromPredecessor) {
  //     fieldMax = await fieldSizeTotal_fromFarmLandsRef(overwriteFieldMax_fromPredecessor);
  //   } else {
  //     if (!fieldMax && farmLandId) {
  //       fieldMax = await fieldSizeTotal_fromFarmLandsRef(farmLandId);
  //     }
  //   }
  // }, [techOpsForOpNumFetched]);

  // HELPS to clean up all assets added after cilcking to TracktorIcon
  // "Внесение СЗР", "Обработка СЗР", добавить 5 СЗР => "TracktorIcon" =>
  // "Добавить новую техоперацию", "Внесение СЗР", "Обработка СЗР" => чтобы стало 0 СЗР
  useEffect(() => {
    function unregisterOnUnmount_cleanupFunction() {
      devlog(`UNMOUNTED TechOperationsEditingPage.tsx`);

      resetFieldsBelowSubGroup();

      // resetFieldsAboveSubGroup:
      setValue("id", "");
      setValue("operationNumber", "");
      setValue("techOperationGroupId", undefined);
      setValue("techOperationSubGroupId", undefined);

      setCropIdWrapper(undefined);

      // fields untouched: seasonId farmId farmLandId cropType CropType_basicHumidity CropType_basicImpurity

      // setDefaultValues({
      //   ...new TechOperation().setFarmLandId(farmLandId_fromUrl).asFormData,
      //   startDate: null,
      //   finishDate: null,
      // });
    }
    return unregisterOnUnmount_cleanupFunction;
  }, []); // eslint-disable-line

  useEffect(() => {
    // when we received farmLands from backend,
    // set farmLandFromTechOp.cropId for seeds
    // if techOpFetched.cropId was undefined

    if (farmLand_fromTechOp_orUrl === undefined) {
      return;
    }

    if (
      farmLand_fromTechOp_orUrl.cropId === undefined ||
      farmLand_fromTechOp_orUrl.cropId.length === 0
    ) {
      return;
    }

    if (techOpFetched === undefined) {
      return;
    }

    if (techOpFetched.cropId !== undefined && techOpFetched.cropId.length > 0) {
      return;
    }

    const newCropId = farmLand_fromTechOp_orUrl.cropId;
    // devlog(`FOR_EFFECT [set farmLandFromTechOp.cropId for seeds] newCropId=[${newCropId}]`);

    setCropIdWrapper(newCropId);
  }, [farmLand_fromTechOp_orUrl, techOpFetched, setValue, setCropIdWrapper]);

  const onTechOpFetched = useCallback(
    (techOp2: TechOperation) => {
      if (!techOp2) {
        return; // AVOIDING "ReferenceError: techop is not defined"
      }

      // copypasted from effect below FETCHED_NOW_WHEN_techOpId!==undefined
      resetFieldsBelowSubGroup();

      setDefaultValues(techOp2.asFormData);
      absorbTechOp_toDefaultValues(techOp2);
      setRenderAfterControllerFilled(true);

      if (techOp2.operationNumber) {
        // triggers areaHistory_forOpNumberRequest => useAreaHistoryRequestor
        setValue("operationNumber", techOp2.operationNumber);
        setValue("farmLandId", techOp2.farmLandId);
        setValue("techOperationGroupId", techOp2.techOperationGroupId);

        // triggers areaHistoryCalculatorInput => useAreaHistoryCalculator
        setValue("techOperationSubGroupId", techOp2.techOperationSubGroupId);
        setValue("seasonId", techOp2.seasonId);
        // setValue("operationNumber", techOp2.operationNumber);

        // shouldTrigger: true, // validate form, disable SAVE when invalid
        // 3519 этот trigger() не вляет на вычисленные по формуле поля
        // for прямое комбайнирование, 3 disabled fields should NOT become red
        trigger();
      }

      if (techOp2.seasonId) {
        setSeasonDatesForSeasonId(techOp2.seasonId);

        if (appFilterSeasonId !== techOp2.seasonId) {
          // upper FARM dropdown is reset to whatever techOp.seasonId
          // (fixing backend bug when a new TO is assigned to another season)
          dispatch(
            setFilterAction({ filter: FilterName.SeasonId, value: techOp2.seasonId })
          );
        }
      }

      // TODO FIXME how appFilterFarmId is now a number?? is that a long64 with GUID inside????
      if (`${appFilterFarmId}` !== techOp2.farmId) {
        // upper FARM dropdown is reset to whatever techOp.farmId
        // (fixing backend bug when a new TO is assigned to another farm / field)
        dispatch(setFilterAction({ filter: FilterName.FarmId, value: techOp2.farmId }));
      }
    },
    [
      dispatch,
      // setFilterAction,
      resetFieldsBelowSubGroup,
      setDefaultValues,
      absorbTechOp_toDefaultValues,
      setRenderAfterControllerFilled,
      setValue,
      trigger,
      setSeasonDatesForSeasonId,
      appFilterFarmId,
      appFilterSeasonId,
    ]
  );

  // const [errorFetchOp, setErrorFetchOp] = useState<string>();
  const fetchTechOp = useCallback(
    (techOpIdToFetch: string) => {
      // the backend will check if "id" is a valid (existing) GUID
      fetchTechOp_trigger(techOpIdToFetch)
        .unwrap()
        .then((techOp1: TechOperation) => {
          setTechOpFetched(techOp1);
          onTechOpFetched(techOp1);
        })
        .catch((ex) => {
          // variable errorFetchOp !== undefined now, printed on next render
          devlog(`fetchTechOp_trigger(): EXCEPTION`, ex);
        });
    },
    [
      fetchTechOp_trigger,
      // setTechOpFetched,
      onTechOpFetched,
      //  setErrorFetchOp
    ]
  );

  useEffect(() => {
    if (!appFilterFarmId || !appFilterSeasonId || !allSeasonsSorted) {
      return;
    }

    if (techOpId === undefined) {
      return;
    }

    if (techOpId === PATHS.EDIT_NEW_PAGE) {
      // below we are on /tech-operations/editing/new
      // or clicked techOps => edit any => ДОБАВИТЬ
      setTechOpFetched(undefined);
      setDefaultFarmAndSeason();
      return;
    }

    // below we are on /tech-operations/editing/8A1A5443-A980-4C25-B03A-000650CE21EE
    // resetFieldsBelowSubGroup(); // moved to onTechOpFetched(); (useless there)
    // FETCHED_NOW_WHEN_techOpId!==undefined fetchTechOp(techOpId);

    resetFieldsBelowSubGroup(); // moved to onTechOpFetched(); (useless there)
    fetchTechOp(techOpId);

    // eslint-disable-next-line
  }, [
    appFilterFarmId,
    appFilterSeasonId,
    allSeasonsSorted,
    techOpId,
    // AVOID_DUPLICATE_CALL resetFieldsBelowSubGroup,
    // AVOID_DUPLICATE_CALL fetchTechOp,
    // AVOID_DUPLICATE_CALL setDefaultFarmAndSeason,
    // AVOID_DUPLICATE_CALL setRenderAfterControllerFilled,
  ]);

  const areaProcessedHistoryLines = useMemo(() => {
    if (areaProcessedHistory !== undefined) {
      return Array.from(areaProcessedHistory.keys());
    } else {
      return [];
    }
  }, [areaProcessedHistory]);

  useEffect(() => {
    if (!watchFields.farmLandId) {
      setValue("techOperationSubGroupId", "");
      clearAreaHistory();
    }
  }, [
    // watchFields.farmLandId changes when:
    // 1) FARM selector gets loaded
    // 2) "Добавить в №3" => F5
    // 3) by DIRECT LINK sent via messenger to "Добавить в №3" page
    watchFields.farmLandId,
    setValue,
    clearAreaHistory,
  ]);

  // useEffect(() => {
  //   if (fieldSizeTotal_fromFarmLands === undefined) {
  //     return;
  //   }
  //   if (opno_fromForm !== OPERATION_NUMBER_NEW) {
  //     return;
  //   }
  //   setFieldSizeAvailable(fieldSizeTotal_fromFarmLands);
  // }, [fieldSizeTotal_fromFarmLands, opno_fromForm]);

  const addingToSameOpNumber = useMemo(() => {
    const urlParamsInvalid =
      !farmLandId_fromUrl ||
      !techOpGroup_fromUrl ||
      !techOpSubGroupId_fromUrl ||
      !opNo_fromUrl;
    return !urlParamsInvalid;
  }, [farmLandId_fromUrl, techOpGroup_fromUrl, techOpSubGroupId_fromUrl, opNo_fromUrl]);

  // const onOperationNumberChanged = useCallback(() => {
  //   // below we are on /tech-operations/editing/new
  //   if (opno_fromForm === OPERATION_NUMBER_NEW) {
  //     absorbAvailableFieldSize_fromAllAgroWorks([]);
  //   } else if (opno_fromForm > 0) {
  //     // editing/new => "Номер операции" dropdown's onChange()
  //     fetchAreaHistory_forOpNumber({
  //       opNum: opno_fromForm,
  //       onFetchedAreaHistory_nonEmpty: (techOpsForOpNum1) => {
  //         absorbAvailableFieldSize_fromAllAgroWorks(techOpsForOpNum1);
  //       },
  //     });
  //   }
  // }, [
  //   opno_fromForm,
  //   fetchAreaHistory_forOpNumber,
  //   absorbAvailableFieldSize_fromAllAgroWorks,
  // ]);

  // useEffect(() => {
  //   if (addingToSameOpNumber) {
  //     return; // AVOID_DUPLICATE_NEWORK_CALL we have a separate effect prepareFieldsForSameNumberAdd()
  //   }

  //   if (fieldSizeTotal_fromFarmLands === undefined) {
  //     return;
  //   }

  //   if (techOpId !== undefined && techOpId !== PATHS.EDIT_NEW_PAGE) {
  //     // editing/8EF970C5-E5AE-4FB3-A023-191DF2E149A2
  //     return;
  //   }

  //   onOperationNumberChanged();
  // }, [
  //   addingToSameOpNumber,
  //   fieldSizeTotal_fromFarmLands, // when FARM selector gets loaded
  //   techOpId,
  //   onOperationNumberChanged,
  // ]);

  const canChangeSubGroup = useMemo(() => {
    if (!watchFields.farmLandId) {
      return false;
    }
    if (!watchFields.techOperationGroupId) {
      return false;
    }
    // if (operationNumber > 0) {
    //   return false;
    // }
    if (isFetchingTechOp) {
      return false;
    }
    if (hasSubGroupId) {
      return false; // should never happen as watchFields.techOperationSubGroupId is disabled
    }
    if (isExistingTechOperation) {
      return false; // never reset data we received from the backend
    }
    return true;
  }, [watchFields, isFetchingTechOp, hasSubGroupId, isExistingTechOperation]);

  const onSubGroupChanged = useCallback(
    (newSubGroupId: string) => {
      if (!canChangeSubGroup) {
        devlog(
          `onSubGroupChanged(): canChangeSubGroup=[${canChangeSubGroup}]
           does not restrict TechOpSubGroup dropdown?`
        );
        return;
      }

      if (newSubGroupId === "") {
        return; // during isLoading
      }
      resetFieldsBelowSubGroup();

      // RHFAutoCompleteSimple:onChangeControllerHandler(newOption?.id);
      // already invoked but but once more:
      setValue(
        "techOperationSubGroupId",
        newSubGroupId,
        { shouldValidate: true } // shouldValidate makes isSubmitEnabled=true
      );

      // if (techOpsForOpNum) {
      //   absorbAvailableFieldSize_fromAllAgroWorks(
      //     techOpsForOpNum,
      //     defaultValues.id,
      //     defaultValues.seasonId,
      //     newSubGroupId
      //   );
      // } else {
      //   // adding a NEW from TracktorIcon http://localhost:3000/tech-operations/editing/new
      //   // after we just added a new techop => no opNum, no techOpsForOpNum
      //   fetchAreaHistory_forOpNumber_absorbFieldSize({
      //     forOpNumber: opno_fromForm,
      //     forTechOpId: defaultValues.id, // "" after cleanupFunction
      //     farmLandId: defaultValues.farmLandId || "", // undefined after cleanupFunction
      //     techOperationGroupId: defaultValues.techOperationGroupId || "", // undefined after cleanupFunction
      //     techOperationSubGroupId: newSubGroupId,
      //     seasonId: defaultValues.seasonId, // undefined after cleanupFunction
      //     shouldTrigger: true, // validate form, disable SAVE when invalid
      //   });
      // }
    },
    [
      canChangeSubGroup,
      resetFieldsBelowSubGroup,
      setValue,
      // techOpsForOpNum,
      // absorbAvailableFieldSize_fromAllAgroWorks,
      // defaultValues,
      // fetchAreaHistory_forOpNumber_absorbFieldSize,
      // opno_fromForm,
    ]
  );

  const canChangeGroup = useMemo(() => {
    if (!techOperationsGroups.length) {
      return false;
    }
    if (addingToSameOpNumber) {
      return false;
    }
    if (isFetchingTechOp) {
      return false;
    }
    if (hasSubGroupId) {
      return false; // should never happen as watchFields.techOperationSubGroupId is disabled
    }
    if (isExistingTechOperation) {
      return false; // never reset data we received from the backend
    }
    return true;
  }, [
    techOperationsGroups,
    addingToSameOpNumber,
    isFetchingTechOp,
    hasSubGroupId,
    isExistingTechOperation,
  ]);

  const onGroupChanged = useCallback(
    (newGroupId: string) => {
      resetFieldsBelowSubGroup();
      if (!canChangeGroup) {
        devlog(
          `onGroupChanged(): canChangeGroup=[${canChangeGroup}]
           does not restrict TechOpGroup dropdown?`
        );
        return;
      }
      if (newGroupId === "") {
        return; // during isLoading
      }

      // below we are after /new => techOperationGroupId dropdown was changed
      setValue("techOperationSubGroupId", "");
      setValue("operationNumber", OPERATION_NUMBER_NEW, {
        shouldValidate: true,
      });
    },
    [canChangeGroup, setValue, resetFieldsBelowSubGroup]
  );

  const onOpNumbersForSubGroupFetched = useCallback(
    (subGroupIdByOpNoFetched: Map<number, ITechOperationDto>) => {
      const opNumbers: OperationNumber[] = [];
      for (const tuple of Array.from(subGroupIdByOpNoFetched.entries())) {
        const opNumber: number = tuple[0];
        const subGroupTechOp: ITechOperationDto = tuple[1];

        let dropdownOptionLabel = `№${opNumber.toString()}`;

        const subGroupNameResolved = techOperationsSubGroups.find(
          (x) => x.id === subGroupTechOp.techOperationSubGroupId
        );
        if (subGroupNameResolved) {
          dropdownOptionLabel += `: ${subGroupNameResolved.name}`;
        }

        const predecessorFound = farmLands?.find(
          (x) => x.id === subGroupTechOp.Real_farmLandId
        );
        if (!predecessorFound) {
          const url = `api/Farms/${appFilterFarmId?.toString()}/FarmLands`;
          // eslint-disable-next-line no-console
          console.warn(
            `PREDECESSOR_IS_DELETED [${subGroupTechOp.Real_farmLandName}] url[${url}];`
            //+ ` farmLands:`, farmLands
          );
        }

        if (
          subGroupTechOp.Real_farmLandId === undefined || // TESTING backend Feb-09
          subGroupTechOp.Real_farmLandId === subGroupTechOp.farmLandId
        ) {
          const farmLandFound = farmLands?.find(
            (x) => x.id === subGroupTechOp.farmLandId
          );
          const farmLandName_formatted = farmLandFormatCombined(
            subGroupTechOp.farmLandName,
            farmLandFound
          );
          dropdownOptionLabel += ` на поле ${farmLandName_formatted}`;
        } else {
          const Real_farmLandName_formatted = farmLandFormatCombined(
            subGroupTechOp.Real_farmLandName,
            predecessorFound
          );
          dropdownOptionLabel += ` на поле-предшественнике ${
            Real_farmLandName_formatted || ""
          }`;
        }

        opNumbers.push({
          // OPERATION_NUMBER DESC in SQL query
          name: dropdownOptionLabel,
          id: opNumber,
        });
      }

      let nextOpNumber =
        opNumbers.length > 0 ? Math.max(...opNumbers.map((x) => x.id)) + 1 : 1;
      if (nextOpNumber <= 0) {
        nextOpNumber = 1; // when previous was -1 (error; shouldn't be saved by backend)
      }
      setNextOperationNumber(nextOpNumber);

      const OPERATION_NEW = {
        name: `Добавить новый (№${nextOpNumber})`,
        id: OPERATION_NUMBER_NEW,
      };

      opNumbers.unshift(OPERATION_NEW);

      setAvailableOperationNumbers(opNumbers);
      setSubGroupIdByOpNo(subGroupIdByOpNoFetched);
    },
    [farmLands, techOperationsSubGroups, appFilterFarmId, setNextOperationNumber]
  );

  const currentTechOperationSubGroup = useMemo(() => {
    return findModelByProperty(
      techOperationsSubGroups,
      watchFields.techOperationSubGroupId
    );
  }, [techOperationsSubGroups, watchFields.techOperationSubGroupId]);

  const isTechAssetsRequired = useMemo(
    () =>
      currentTechOperationSubGroup &&
      Object.hasOwn(currentTechOperationSubGroup, "isTechRequired") // TODO: remove it after pred assertions appeared
        ? currentTechOperationSubGroup.isTechRequired
        : true,
    [currentTechOperationSubGroup]
  );
  const isEmployeesRequired = useMemo(
    () =>
      currentTechOperationSubGroup &&
      Object.hasOwn(currentTechOperationSubGroup, "isEmployeeRequired") // TODO: remove it after pred assertions appeared
        ? currentTechOperationSubGroup.isEmployeeRequired
        : true,
    [currentTechOperationSubGroup]
  );

  const { autoHideJsx, autoHide } = useAutoHide();
  const [
    fetchOpNumbers_forSubGroup_trigger,
    {
      isFetching: isLoadingOpNumbers_forSubGroup,
      // data: opNumbersFetched,
      // error: errorFetchOpNumbers_forGroup,
    },
  ] = useLazyFetchOpNumbers_forGroupQuery();

  const fetchOpNumbers_forSubGroup = useCallback(() => {
    if (
      watchFields.farmLandId === undefined ||
      watchFields.techOperationGroupId === undefined
    ) {
      devwarn(
        `DONT_REQUEST_fetchOpNumbers_forSubGroup_AFTER_CLEARING_DROPDOWN: 
        watchFields.farmLandId[${watchFields.farmLandId}] ||
         watchFields.techOperationGroupId[${watchFields.techOperationGroupId}]`
      );
      return;
    }

    const request: FetchOperationNumbers_forGroupRequest = {
      farmLandId: watchFields.farmLandId,
      techOperationGroupId: watchFields.techOperationGroupId,
    };

    fetchOpNumbers_forSubGroup_trigger(request)
      .unwrap()
      .then(onOpNumbersForSubGroupFetched)
      .catch((ex) => {
        setNextOperationNumber(undefined);
        setAvailableOperationNumbers([]);

        devlog(`TechOpEditFormContent,tsx:fetchOpNumbers_forSubGroup_trigger():`, ex);

        const err: string = asLoopbackError(ex) || "UNKNOWN_ERROR_DTO";
        autoHide(err, true, 10000);
      });
  }, [
    fetchOpNumbers_forSubGroup_trigger,
    // watchFields.farmLandId changes when:
    // 1) FARM selector gets loaded
    // 2) "Добавить в №3" => F5
    // 3) by DIRECT LINK sent via messenger to "Добавить в №3" page
    watchFields.farmLandId,
    watchFields.techOperationGroupId,
    onOpNumbersForSubGroupFetched,
    setNextOperationNumber,
    autoHide,
  ]);

  useEffect(() => {
    if (!watchFields.farmLandId || !watchFields.techOperationGroupId) {
      // clean-up after we changed FIELD
      setNextOperationNumber(undefined);
      setAvailableOperationNumbers([]);
      return;
    }
    fetchOpNumbers_forSubGroup();
  }, [
    // watchFields.farmLandId changes when:
    // 1) FARM selector gets loaded
    // 2) "Добавить в №3" => F5
    // 3) by DIRECT LINK sent via messenger to "Добавить в №3" page
    watchFields.farmLandId,
    watchFields.techOperationGroupId,
    setNextOperationNumber,
    fetchOpNumbers_forSubGroup,
  ]);

  useEffect(() => {
    reset(defaultValues);
  }, [reset, defaultValues]);

  const farmLandCropNameFromTechOp: string | undefined = useMemo(() => {
    if (farmLand_fromTechOp_orUrl === undefined) {
      return undefined;
    }
    const ret = cropTypes.find((x) => x.id === farmLand_fromTechOp_orUrl.cropTypeId);
    return ret?.name;
  }, [cropTypes, farmLand_fromTechOp_orUrl]);

  useDisableGlobalFilters();

  const remainderForNewAndExisting = useMemo(() => {
    // TODO: remove after testing
    // остаток для нового и существующего?
    // const preparedOpNumbersEntriesForIteration = Object.values(
    //   opNumbersFetched ? Object.fromEntries(opNumbersFetched.entries()) : {} // а что в опнамбер входит?
    // );
    // console.log(
    //   preparedOpNumbersEntriesForIteration,
    //   " preparedOpNumbersEntriesForIteration"
    // );
    // const totalAreaSumWhenNoTechOpNum = preparedOpNumbersEntriesForIteration.reduce(
    //   (acc, fieldJob) => {
    //     return acc + fieldJob.fieldSize;
    //   },
    //   0
    // );

    // let finalFreeArea = 0;

    // if (fieldSizeTotal_fromFarmLands) {
    //   finalFreeArea = fieldSizeTotal_fromFarmLands - totalAreaSumWhenNoTechOpNum;
    // } else {
    //   finalFreeArea = remainderForThisOpNumberAndSubGroup || 0;
    // }

    // return round(finalFreeArea, 2);
    return +remainderForThisOpNumberAndSubGroup;
  }, [
    remainderForThisOpNumberAndSubGroup,
    // remainderForThisOpNumberAndSubGroup,
    // fieldSizeTotal_fromFarmLands,
    // opNumbersFetched,
  ]);

  const prepareFieldsForSameNumberAdd = useCallback(() => {
    const urlParamsInvalid =
      !farmLandId_fromUrl ||
      !techOpGroup_fromUrl ||
      !techOpSubGroupId_fromUrl ||
      !opNo_fromUrl;
    if (urlParamsInvalid) {
      return;
    }

    const opNoParsed = parseInt(opNo_fromUrl);
    if (opNoParsed <= 0) {
      return;
    }

    setDefaultValues({
      // ...new TechOperation().setFarmLandId(farmLandId_fromUrl).asFormData,
      ...emptyFormTechOp(
        appFilterFarmId,
        appFilterSeasonId ?? "SELECT_SEASON_FROM_UPPER_DROPDOWN"
      ),

      farmLandId: farmLandId_fromUrl,
      techOperationGroupId: techOpGroup_fromUrl,
      techOperationSubGroupId: techOpSubGroupId_fromUrl,
      operationNumber: opNoParsed,
      startDate: null,
      finishDate: null,
      assets: [],
    });
    setDefaultFarmAndSeason();

    // fetchAreaHistory_forOpNumber_absorbFieldSize({
    //   forOpNumber: opNoParsed,
    //   forTechOpId: "NO_GUID_SHOULD_MATCH",
    //   farmLandId: farmLandId_fromUrl,
    //   techOperationGroupId: techOpGroup_fromUrl,
    //   techOperationSubGroupId: techOpSubGroupId_fromUrl,
    // });

    if (
      subGroupIds_forCropType === undefined &&
      farmLand_fromTechOp_orUrl !== undefined
    ) {
      fetchSubGroups_forCropType(farmLand_fromTechOp_orUrl.cropTypeId);
    }
  }, [
    farmLandId_fromUrl,
    techOpGroup_fromUrl,
    techOpSubGroupId_fromUrl,
    opNo_fromUrl,
    setDefaultValues,
    // emptyFormTechOp,
    appFilterFarmId,
    appFilterSeasonId,
    setDefaultFarmAndSeason,
    // fetchAreaHistory_forOpNumber_absorbFieldSize,
    subGroupIds_forCropType,
    farmLand_fromTechOp_orUrl,
    fetchSubGroups_forCropType,
  ]);

  const prepared = useRef<boolean>(false);
  useEffect(() => {
    if (!addingToSameOpNumber) {
      return;
    }
    if (!farmLands) {
      return;
    }
    if (prepared.current === true) {
      return;
    }
    prepared.current = true;

    // below we clicked on "Добавить в №2" button
    prepareFieldsForSameNumberAdd();

    // when we opened "Добавить в №3" URL, by direct link (WITHOUT clicking on this button),
    // we need subGroups loaded as well:
    // setValue("farmLandId", farmLandId_fromUrl); // will trigger effect with fetchSubGroups_forCropType();

    // or invoke the same HERE, without THAT effect
    // TODO: need to find all "watchFields.farmLandId" and confirm no side-effects are lost
    const farmLand = farmLands.find((x) => x.id === farmLandId_fromUrl);
    if (!farmLand) {
      return;
    }
    fetchSubGroups_forCropType(farmLand.cropTypeId);
  }, [
    addingToSameOpNumber,
    prepared,
    prepareFieldsForSameNumberAdd,
    farmLands,
    // setValue,
    farmLandId_fromUrl,
    fetchSubGroups_forCropType,
  ]);

  // useEffect(() => {
  //   fetchAreaHistory_forOpNumberSelected_absorbFieldSize_ref.current =
  //     fetchAreaHistory_forOpNumberSelected_absorbFieldSize;
  //   fetchOpNumbers_forSubGroup_ref.current = fetchOpNumbers_forSubGroup;
  // }, [
  //   fetchAreaHistory_forOpNumberSelected_absorbFieldSize_ref,
  //   fetchAreaHistory_forOpNumberSelected_absorbFieldSize,
  //   fetchOpNumbers_forSubGroup_ref,
  //   fetchOpNumbers_forSubGroup,
  // ]);

  // [6:22 PM] Михаил Овчинников
  // realfarmlandid это на какое состояние поля заведена ТО. Тогда:
  // 1. Правило валидации на площадь применять к тем Техоперациям,
  //  где farmlandid = realfarmlandid.Не применять если farmlandid != realfarmlandid.
  // 2. Желательно заблокировать для редактирования ТО с farmlandid != realfarmlandid.
  //  Оставить возможным редактирование если farmlandid = realfarmlandid.
  const disabled = useMemo(() => {
    if (isExistingTechOperation) {
      // /tech-operations/editing/BA6429A7-AE01-4556-A64E-0055C3507784
      return false; // can edit any EXISTING techop
    }

    const techOpForDropdownSelection = subGroupIdByOpNo?.get(opno_fromForm);
    if (techOpForDropdownSelection) {
      const cantAddToPredecessor = isPredecessorDto(techOpForDropdownSelection);
      return cantAddToPredecessor;
    }

    if (!techOpFetched) {
      return false; // loading
    }
    // /tech-operations/editing/new
    return techOpFetched.isPredecessor;
  }, [techOpFetched, isExistingTechOperation, subGroupIdByOpNo, opno_fromForm]);

  useEffect(() => {
    // affects parent form's SAVE button, controlled from here
    setDisabled?.(disabled);
  }, [setDisabled, disabled]);

  const disablePresetFarmLand = useMemo(() => {
    if (!farmLands) {
      return false; // don't disable while loading
    }

    if (!farmLandId) {
      return false; // don't disable if we edit/add techop from AllTechops page
    }

    //invoked from map => farmLand clicked => techops => add new
    const farmLandFound = farmLands.find((x) => x.id === farmLandId);
    if (!farmLandFound) {
      return false; // show "не найдено в текущем хозяйстве \ сезоне"
    }
    return true; // once found, select farmLand in dropdown and disable
  }, [farmLandId, farmLands]);

  if (errorFetchOp) {
    // http://localhost:3000/tech-operations/editing/74E55B6A-4FBA-42C0-9D4C-A2F50DBBA889
    // https://ekocrop-staging.ekoniva-apk.org/api/TechOperationAgregatedsV2/74E55B6A-4FBA-42C0-9D4C-A2F50DBBA889
    // ?access_token=xAJBt9VRQvfTiSQmeD2gGpfFDGKmZP4vDVEJNxCrZv1fIo0L3QNEsRW3igN5IrG1
    // {
    //     "error": {
    //       "statusCode": 500,
    //       "name": "Error",
    //       "message": "Не найден экземпляр TechOperationAgregatedV2",
    //       "stack": "Error: Не найден экземпляр TechOperationAgregatedV2\n
    // at Function.TechOperationAgregatedV2.findModelById (/app/common/models/tech-operation-agregated.v2.js:167:13)"
    //   }
    // }

    return (
      <ScreenCenter minHeight={"50vh"} sx={{ flexDirection: "column" }}>
        <Box>{asTechOperationAgregatedsV2Error(errorFetchOp)}</Box>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            reset();
            navigate(PATHS.TECHOPERATIONS_PAGE);
          }}
        >
          В список Техопераций
        </Button>
      </ScreenCenter>
    );
  }

  const isTechOperationEditingAssetsAvailable =
    currentTechOperationSubGroup &&
    (currentTechOperationSubGroup.usesSeeds ||
      currentTechOperationSubGroup.usesFertilisers ||
      currentTechOperationSubGroup.usesChemicals);

  return (
    <>
      <Grid container={true} spacing={2}>
        <Grid item={true} xs={5}>
          <RHFAutocompleteSimple<IFarmLandDto>
            name="farmLandId"
            dataTest={TechOpsTestIds.TechOpsEditField}
            rules={{ required: true }}
            defaultValue={defaultValues.farmLandId || ""}
            optionGuidToObject={(optionGuid: string) => {
              if (!farmLands) {
                return undefined;
              }
              const ret = farmLands.find((f) => f.id === optionGuid);
              return ret;
            }}
            disabled={
              !farmLands ||
              !farmLands.length ||
              // Boolean(sourceFarmLandId) || // Boolean() is not acceptable in develop
              isExistingTechOperation ||
              addingToSameOpNumber ||
              disablePresetFarmLand
            }
            options={farmLands || []}
            getOptionLabel={formatFarmLand}
            noOptionsText="В хозяйстве нет полей"
            textFieldProps={{
              label: "Поле",
              required: true,
            }}
            style={{ padding: "8px 16px" }}
          />

          {farmLandCropNameFromTechOp === undefined &&
          watchFields.farmLandId &&
          watchFields.farmLandId.length > 0 ? (
            <Box pt={3}>
              В сезоне {appFilterSeasonNameResolved} поля {watchFields.farmLandId} не
              существует
            </Box>
          ) : (
            <></>
          )}

          {isLoadingFarmLands && <CircularProgress size={18} />}

          <RHFAutocompleteSimple<ITechOperationGroupDto>
            name="techOperationGroupId"
            dataTest={TechOpsTestIds.TechOpsEditOpType}
            rules={{ required: true }}
            defaultValue={defaultValues.techOperationGroupId}
            onOptionSelected={(val: ITechOperationGroupDto | null) => {
              onGroupChanged(val?.id || "");
            }}
            optionGuidToObject={(optionGuid: string) => {
              const ret = techOperationsGroups.find((f) => f.id === optionGuid);
              return ret;
            }}
            disabled={!canChangeGroup}
            options={techOperationsGroups}
            noOptionsText={"Нет доступных типов операций"}
            textFieldProps={{
              required: true,
              label: "Тип операции",
            }}
            style={{ padding: "8px 16px" }}
          />

          <RHFAutocomplete
            name="operationNumber"
            dataTest={TechOpsTestIds.TechOpsEditOpNumber}
            rules={{
              required: true,
              min: OPERATION_NUMBER_NEW.toString(),
            }}
            defaultValue={opNo_fromUrl || OPERATION_NUMBER_NEW.toString()}
            renderValue={(selectedId) => {
              if (selectedId === "" || selectedId === OPERATION_NUMBER_NEW.toString()) {
                const OPERATION_NEW = {
                  name: nextOperationNumber
                    ? `Добавить новый (№${nextOperationNumber})`
                    : `Добавить новый`,
                  id: OPERATION_NUMBER_NEW,
                };
                return OPERATION_NEW;
              }

              const found = findModelByProperty(availableOperationNumbers, selectedId);
              return found;
            }}
            AutocompleteProps={{
              disabled:
                !watchFields.farmLandId ||
                !watchFields.techOperationGroupId ||
                isExistingTechOperation ||
                addingToSameOpNumber, // when enabled, opNo_fromUrl will keep resetting dropdown despite other user selection
              options: availableOperationNumbers,
              noOptionsText: "Нет доступных номеров операций",
            }}
            TextFieldProps={{
              required: true,
              label:
                isExistingTechOperation && opno_fromForm <= 0
                  ? `Невалидный номер операции [${watchFields.operationNumber}]`
                  : "Номер операции",
            }}
            style={{ padding: "8px 16px" }}
          />
          {isLoadingOpNumbers_forSubGroup && <CircularProgress size={18} />}

          <RHFAutocompleteSimple<ITechOperationSubGroupDto>
            name="techOperationSubGroupId"
            rules={{ required: true }}
            dataTest={TechOpsTestIds.TechOpsEditOpSubType}
            defaultValue={defaultValues.techOperationSubGroupId}
            onOptionSelected={(val: ITechOperationSubGroupDto | null) => {
              onSubGroupChanged(val?.id || "");
            }}
            optionGuidToObject={(optionGuid: string | null) => {
              const ret = techOperationsSubGroups.find((f) => f.id === optionGuid);
              return ret;
            }}
            disabled={!canChangeSubGroup}
            options={availableTechOperationsSubGroups}
            noOptionsText={"Нет доступных подтипов операций"}
            getOptionLabel={(option) => option.name || ""}
            textFieldProps={{
              required: true,
              label: "Подтип операции",
            }}
            style={{ padding: "8px 16px" }}
          />
        </Grid>

        <Grid item={true} xs={7} style={{ padding: "0px 16px 0px 20px" }}>
          {opno_fromForm > 0 ? (
            isLoadingAreaHistory ? (
              <SpinnerCenter
                minHeight={"30vh"}
                size={60}
                // msg={`Загружается история ${operationNumber}...`}
              />
            ) : (
              <TechopHistory
                operationsForSeason={agroWorksForOpNoForSeason || []}
                // 3628 3626 3625 3630: sum area for agroworks only for ORIGINAL farmLands (no predecessor)
                noPredecessorsList={
                  agroWorksForOpNoForSeason?.filter(isOriginalDto) || []
                }
                techOperationsSubGroups={techOperationsSubGroups}
                subGroupId={
                  techOpFetched?.techOperationSubGroupId ||
                  watchFields.techOperationSubGroupId ||
                  "NO_SUBGROUP"
                }
                fieldMax={fieldSizeTotal_fromFarmLands || 0}
                remainderForThisOpNumber={remainderForThisOpNumberAndSubGroup}
                // onDoubleClick={() => {
                //   if (opno_fromForm <= 0) {
                //     return;
                //   }
                //   // reload field size fill history for current operationNumber
                //   //fetchForOpNumberSelectedAndAbsorbFieldSize(operationNumber);
                //   areaHistoryLoaded_forOpNo_ref.current = undefined;
                //   fetchAreaHistory_forOpNumber({
                //     opNum: opno_fromForm,
                //     onFetchedAreaHistory_nonEmpty: (techOpsForOpNum) => {
                //       // watchFields.subGroup should be set here already
                //       absorbAvailableFieldSize_fromAllAgroWorks(
                //         techOpsForOpNum,
                //         editingThisTechOpId
                //       );
                //     },
                //     Real_farmLandId: techOpFetched?.Real_farmLandId,
                //   });
                // }}
              />
            )
          ) : null}
        </Grid>
      </Grid>

      <List>
        {!watchFields.techOperationSubGroupId ? (
          isFetchingTechOp || isLoadingAssetTypes || isLoadingTechOpAssets ? (
            <SpinnerCenter
              minHeight={"10vh"}
              msg={`Загружается техоперация №${opno_fromForm}...`}
            />
          ) : (
            <></> // OPERATION_NUMBER_NEW
          )
        ) : (
          <>
            {isFetchingTechOp ? (
              <SpinnerCenter
                minHeight={"10vh"}
                msg={
                  `Загружается Техоперация` +
                  // ` ${areaHistoryLoaded_forOpNo_ref.current` +
                  `...`
                }
              />
            ) : (
              <></> // INSERT_BREAKPOINT
            )}

            <Box sx={{ px: 2, pt: 4 }}>
              <Typography variant={"h5"}>Настройки звена</Typography>
            </Box>

            <ListItem>
              <TechOperationEditingTechAssets
                isRequired={isTechAssetsRequired}
                isExistingTechOperation={isExistingTechOperation}
                disabled={disabled}
              />
            </ListItem>

            <ListItem>
              <TechOperationEditingEmployees
                isRequired={isEmployeesRequired}
                isExistingTechOperation={isExistingTechOperation}
                disabled={disabled}
              />
            </ListItem>

            <ListItem>
              <TechOpEditDates
                rhfMethods={rhfMethods}
                remainderForNewAndExisting={remainderForNewAndExisting}
                seasonDates={seasonDates}
                areaProcessedHistoryLines={areaProcessedHistoryLines}
                isLoadingAreaHistory={isLoadingAreaHistory}
                renderAfterControllerFilled={renderAfterControllerFilled}
                disabled={disabled}
              />
            </ListItem>

            <ListItem>
              <RHFTextField
                name="comment"
                id={TechOpsTestIds.TechOpsEditEmployeesWorkInfoComment}
                TextFieldProps={{
                  label: "Комментарий",
                  disabled: disabled,
                }}
              />
            </ListItem>

            <ListItem>
              <TechOperationEditingSubgroupParams
                key={`params:${assetsKeyToResetRhfAssetsArray}`}
                isExistingTechOperation={isExistingTechOperation}
                disabled={disabled}
              />
            </ListItem>

            {isTechOperationEditingAssetsAvailable && (
              <TechOperationEditingAssets
                // KEY_INCREMENT: CLEANS_UP all assets added after switching to another SubGroup
                // "Внесение СЗР", "Обработка СЗР", добавить 5 СЗР => "Дератизация", стало 0 СЗР
                key={`assets:${assetsKeyToResetRhfAssetsArray}`}
                techOpId={techOpId}
                operationNumber={opno_fromForm}
                techOperationSubGroup={currentTechOperationSubGroup}
                isExistingTechOperation={isExistingTechOperation}
                cropId={cropId} // I prefer to pass implicitly instead of setType("cropId", X)
                disabled={disabled}
              />
            )}
          </>
        )}
      </List>
      {autoHideJsx}
      {colorifyError(
        asLoopbackError(
          assetsQuery.error,
          undefined, // "NOT_A_LOOPBACK_ERROR:assetsQuery.error",
          "LOOPBACK_ERROR_assetsQuery.error: "
        ),
        true
      )}
    </>
  );
};

function mergeFormData(
  prevValues: ITechOperationFormData,
  partialToFlush: Partial<ITechOperationFormData>
) {
  const changes: Record<string, string> = {};
  for (const k in partialToFlush) {
    const prevValue = prevValues[k];
    const newValue = partialToFlush[k];
    if (!isEqual(prevValue, newValue)) {
      changes[k] = `${prevValue} => ${newValue}`; // wrong formatting for Date, Map, Array, Object
    }
  }

  if (Object.keys(changes).length === 0) {
    return prevValues; // AVOIDING_UNNECESSARY_RERENDER
  }

  devlog(`DEFAULT_PROPERTIES_CHANGED:`, changes);

  return {
    ...prevValues,
    ...partialToFlush,
  };
}
