import { useEffect, useMemo } from "react";
import { Controller, useFormContext, UseFormReturn } from "react-hook-form";
import { round } from "lodash";
import { isAfter, endOfDay, isBefore, startOfDay, format } from "date-fns";

import { Grid } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";

import { ITechOperationFormData } from "../../shared/interfaces/tech-operation-form-data";
import { HUMAN_DATE_FORMAT } from "../../../../constant";
import { RHFTextField } from "../../../../shared/components/react-hook-form-mui/textfield";
import { SeasonDates } from "../../../../shared/types";
import { IconTooltipClick } from "./icon-tooltip-click";
import { TechOpsTestIds } from "../../shared/types/tech-operation-data-test";

export const TechOpEditDates = ({
  rhfMethods,
  remainderForNewAndExisting,
  seasonDates,
  areaProcessedHistoryLines,
  isLoadingAreaHistory,
  disabled,
  renderAfterControllerFilled,
}: {
  rhfMethods: UseFormReturn<ITechOperationFormData>;
  remainderForNewAndExisting: number;
  seasonDates: SeasonDates;
  areaProcessedHistoryLines: string[];
  isLoadingAreaHistory: boolean;
  disabled?: boolean;
  renderAfterControllerFilled: boolean;
}): JSX.Element => {
  const { control, trigger, setValue, watch } = useFormContext<ITechOperationFormData>();

  const [startDate, finishDate, fieldSize, assets] = watch([
    "startDate",
    "finishDate",
    "fieldSize",
    "assets",
  ]);

  useEffect(() => {
    if (!startDate || !renderAfterControllerFilled) {
      return; // AVOIDING warning "Field is missing with `name` attribute:  startDate"
    }
    trigger("startDate");
    // eslint-disable-next-line
  }, [finishDate, renderAfterControllerFilled, trigger]);

  useEffect(() => {
    if (!finishDate || !renderAfterControllerFilled) {
      return; // AVOIDING warning "Field is missing with `name` attribute:  finishDate"
    }
    trigger("finishDate");
    // eslint-disable-next-line
  }, [startDate, renderAfterControllerFilled, trigger]);

  useEffect(() => {
    if (!fieldSize || !renderAfterControllerFilled) {
      return; // AVOIDING warning "Field is missing with `name` attribute:  fieldSize"
    }
    trigger("fieldSize"); // for editing by GUID, SAVE is disabled otherwise
  }, [remainderForNewAndExisting, fieldSize, renderAfterControllerFilled, trigger]);

  useEffect(() => {
    assets?.forEach((asset, id) => {
      if (asset.applicationRate > 0) {
        const applicationRate = Number(
          (fieldSize ? asset.quantityReal / fieldSize : 0).toFixed(3)
        );
        setValue(`assets.${id}.applicationRate`, applicationRate);
      }
    });
  }, [fieldSize]); // eslint-disable-line

  const remainderMinusEntered = useMemo(() => {
    const ret = remainderForNewAndExisting - (fieldSize || 0);
    return round(ret, 2);
  }, [remainderForNewAndExisting, fieldSize]);

  const remainderTitle = useMemo(() => {
    if (remainderMinusEntered >= 0) {
      return `Завершённая площадь (остаток: ${remainderMinusEntered} га)`;
    } else {
      return `Вы превысили доступную площадь на ${-remainderMinusEntered} га`;
    }
  }, [remainderMinusEntered]);

  return (
    <Grid container={true} spacing={2}>
      <Grid item={true} xs={3}>
        <Controller
          control={control}
          name={"startDate"}
          rules={{
            required: "Дата начала работы должна быть заполнена",
            validate: (date: Date | null) => {
              if (!date) {
                return true; // DEBUGME: seems no nulls after defaultValue & parseISO()
              }

              const seasonStart = new Date(seasonDates?.startedAt || new Date());
              const seasonEnd = new Date(seasonDates?.finishedAt || new Date());
              const now = new Date();

              if (isAfter(date, endOfDay(now))) {
                return `Дата начала работы не должна быть позднее текущей даты`;
              }

              if (isBefore(date, startOfDay(seasonStart))) {
                return `Дата начала работы не должна быть раньше начала сезона ${format(
                  seasonStart,
                  HUMAN_DATE_FORMAT
                )}`;
              }

              if (isAfter(date, endOfDay(seasonEnd))) {
                return `Дата начала работы не должна быть позднее конца сезона ${format(
                  seasonEnd,
                  HUMAN_DATE_FORMAT
                )}`;
              }

              if (finishDate && isAfter(date, endOfDay(finishDate))) {
                return `Дата начала работы не должна быть позднее даты окончания работы`;
              }

              return true;
            },
          }}
          render={({ field, fieldState: { error } }) => (
            <DatePicker
              {...field}
              label="Начало работы"
              format={HUMAN_DATE_FORMAT}
              slotProps={{
                textField: {
                  fullWidth: true,
                  error: !!error,
                  helperText: error ? error.message : "",
                  InputProps: {
                    required: true,
                  },
                  InputLabelProps: {
                    required: true,
                  },
                  variant: "standard",
                },
              }}
              disabled={disabled}

              // cancelLabel={"Отменить"}
              // okLabel={"Подтвердить"}
              // animateYearScrolling={true}
            />
          )}
        />
      </Grid>

      <Grid item={true} xs={3}>
        <Controller
          control={rhfMethods.control}
          name={"finishDate"}
          rules={{
            required: "Дата окончания работы должна быть заполнена",
            validate: (date: Date | null) => {
              if (!date) {
                return true; // DEBUGME: seems no nulls after defaultValue & parseISO()
              }

              const seasonStart = new Date(seasonDates?.startedAt || new Date());
              const seasonEnd = new Date(seasonDates?.finishedAt || new Date());
              const now = new Date();

              if (isAfter(date, endOfDay(now))) {
                return `Дата окончания работы не должна быть позднее текущей даты`;
              }

              if (isBefore(date, startOfDay(seasonStart))) {
                return `Дата окончания работы не должна быть раньше начала сезона ${format(
                  seasonStart,
                  HUMAN_DATE_FORMAT
                )}`;
              }

              if (isAfter(date, endOfDay(seasonEnd))) {
                return `Дата окончания работы не должна быть позднее конца сезона ${format(
                  seasonEnd,
                  HUMAN_DATE_FORMAT
                )}`;
              }

              if (startDate && isBefore(date, startOfDay(startDate))) {
                return `Дата окончания работы не должна быть ранее даты начала работы`;
              }

              return true;
            },
          }}
          render={({ field, fieldState: { error } }) => (
            <DatePicker
              {...field}
              label="Окончание работы"
              format={HUMAN_DATE_FORMAT}
              slotProps={{
                textField: {
                  fullWidth: true,
                  error: !!error,
                  helperText: error ? error.message : "",
                  InputProps: {
                    required: true,
                  },
                  InputLabelProps: {
                    required: true,
                  },
                  variant: "standard",
                },
              }}
              disabled={disabled}

              // cancelLabel={"Отменить"}
              // okLabel={"Подтвердить"}
              // animateYearScrolling={true}
            />
          )}
        />
      </Grid>

      <Grid item={true} xs={5}>
        <RHFTextField
          id={TechOpsTestIds.TechOpsEditEmployeesWorkInfoSquare}
          name="fieldSize"
          rules={{
            required: true,
            min: 0.001,
            max: disabled ? undefined : remainderForNewAndExisting,
          }}
          TextFieldProps={{
            required: true,
            disabled: disabled,
            type: "number",

            label: remainderTitle,
            title: `${areaProcessedHistoryLines.join("\n")}`,

            inputProps: {
              min: 0.001,
              max: remainderForNewAndExisting,
              step: 0.001,
            },
          }}
        />
      </Grid>

      <Grid item xs={1}>
        <IconTooltipClick
          isLoading={isLoadingAreaHistory}
          onDoubleClick={() => {
            // if (operationNumber <= 0) {
            //   return;
            // }
            // // reload field size fill history for current operationNumber
            // opNumLoadedRef.current = undefined;
            // fetchForOpNumberSelected(operationNumber, (techOpsForOpNum) => {
            //   absorbAvailableFieldSizeFromAllRecords(techOpsForOpNum, techOpsForOpNum[0]);
            // });
          }}
        >
          <pre>{areaProcessedHistoryLines.join("\n")}</pre>
        </IconTooltipClick>
      </Grid>
    </Grid>
  );
};
