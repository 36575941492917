import { ControlledFieldsType, EntityType } from "../types";

export const fieldsAndEntityJuxtapositionPipe = (
  contdFields: ControlledFieldsType,
  entities: EntityType[]
) =>
  entities.reduce((acc, entity) => {
    acc[entity] = contdFields.filter(({ field }) => field.entityType === entity);
    return acc;
  }, {});

export const prepareFieldsForTabs = (fieldsJuxtaposition) =>
  Object.entries(fieldsJuxtaposition).reduce((acc, item) => {
    const field = item[1] as ControlledFieldsType;
    const lastItem = field[field.length - 1];
    if (!lastItem) {
      acc = { ...acc };
    } else {
      acc = { ...acc, [item[0]]: lastItem.index };
    }
    return acc;
  }, {});
