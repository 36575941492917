import { round } from "lodash";

import { ITechOperationSubGroupDto } from "../dtos/tech-operation-sub-group.dto";
import { ITechOperationDto } from "../dtos/tech-operation.dto";

// pointer to this pure function is new each rerender, but calculation result is the same
export function buildTooltip(
  operationsForSeason: ITechOperationDto[],
  techOperationsSubGroups: ITechOperationSubGroupDto[],
  fieldMax: number,
  remainderForThisOpNumber?: number
): {
  newAreaHistory: Map<string, string>;
  areaProcessed: number;
  remainder: number;
} {
  const newAreaHistory = new Map<string, string>();
  newAreaHistory.set(`${fieldMax.toFixed(2)}га общая площадь поля`, "");
  newAreaHistory.set(`----------------------------------------------`, "");
  let areaProcessed = 0;
  for (const op of operationsForSeason) {
    const opNo = op.operationNumber || 0;
    const subGroupResolved = techOperationsSubGroups.find(
      (x) => x.id === op.techOperationSubGroupId
    );
    const dateRange =
      op.startDate !== op.finishDate
        ? `${op.startDate}...${op.finishDate}`
        : `${op.startDate}`;
    const historyLine =
      `${op.fieldSize.toFixed(2)}га обработано ${dateRange}` +
      ` (Тех.Оп.№${opNo}` +
      ` ${subGroupResolved?.name || ""})`;
    newAreaHistory.set(historyLine, op.techOperationSubGroupId);
    areaProcessed += op.fieldSize;
  }
  newAreaHistory.set(`-----------------------------------------------`, "");
  newAreaHistory.set(`${areaProcessed.toFixed(2)}га обработано суммарно`, "");
  newAreaHistory.set(`${(fieldMax - areaProcessed).toFixed(2)}га доступная площадь`, "");

  if (remainderForThisOpNumber) {
    newAreaHistory.set(`------------------------------------------------`, "");
    newAreaHistory.set(
      `${remainderForThisOpNumber.toFixed(2)}га максимальная площадь при редактировании`,
      ""
    );
  }

  const sizeField = Number((fieldMax - areaProcessed).toFixed(2));
  const remainder = sizeField ? sizeField : fieldMax - areaProcessed; // this obviously should be separated from tooltip text logic

  return {
    newAreaHistory,
    areaProcessed,
    remainder: round(remainder, 2),
  };
}
