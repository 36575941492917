import { join } from "array-join";
import { IMeasure } from "./measure";
import { ITechOperationMeasuresSet } from "./tech-operation-measures-set";
import { ITechOperationParam } from "./tech-operation-param";
import { devlog } from "../../../../shared/utils/log";

export function measureByAlias(
  techOperationsParams: ITechOperationParam[],
  techOperationsMeasuresSets: ITechOperationMeasuresSet[],
  measures: IMeasure[]
): Record<string, string> {
  const measuresParams = join(
    techOperationsParams,
    techOperationsMeasuresSets,
    (params) => params.id,
    (sets) => sets.operationParamId,
    (params, sets) => ({ params, sets })
  );

  const flatMeasuresWithParams = join(
    measuresParams,
    measures,
    (mesParam) => mesParam.sets.measureId,
    (measure) => measure.id,
    (mesParams, measure) => ({
      operationParamId: mesParams.sets.operationParamId,
      operationParamAlias: mesParams.params.alias,
      measureId: measure.id,
      measureName: measure.measure,
      operationParamIsDeleted: mesParams.params.isDeleted,
      operationMeasureSetIsDeleted: mesParams.sets.isDeleted,
      measureIsDeleted: measure.isDeleted,
    })
  );

  const ret = {};
  for (const row of flatMeasuresWithParams) {
    const deletedSuffix =
      row.operationMeasureSetIsDeleted ||
      row.operationParamIsDeleted ||
      row.measureIsDeleted
        ? " УДАЛЕНО"
        : "";
    ret[row.operationParamAlias] = `${row.measureName}${deletedSuffix}`;
  }

  devlog("measureByAlias", ret);

  return ret;
}
