import { v4 as uuidv4 } from "uuid";
import { ProductionType } from "../../../../shared/enums";
import { ITechOperationValueDto } from "../dtos/tech-operation-param.dto";
import { ITechOperationAssetFormData } from "./tech-operation-asset-form-data";

export interface ITechOperationFormData {
  id: string;
  farmId: string;
  farmLandId?: string; // undefined when clicked X after selection in the dropdown
  cropTypeId: string;
  cropId?: string; // techop-edit-seed.tsx uses it to narrow dropdown options
  startDate: Date | null;
  finishDate: Date | null;
  techOperationGroupId?: string; // undefined when clicked X after selection in the dropdown
  techOperationSubGroupId?: string; // undefined when clicked X after selection in the dropdown
  fieldSize: number | null;
  operationNumber?: number | "";
  comment: string;
  productionType: ProductionType;
  techAssetId: string | null;
  trailerAssetId: string;
  employeeId: string;
  seasonId?: string; // probably, should not be nullable
  massCleanedProduct?: number;
  productivitySet?: number;
  params: Record<
    string, // number, // ITechOperationValueDto["operationParamId"]
    ITechOperationValueDto
  >;
  seedsMAE: { id: string; name: string } | null;
  fertilisersMAE: { id: string; name: string } | null;
  chemicalsMAE: { id: string; name: string } | null;
  assets?: ITechOperationAssetFormData[];
}

export function emptyFormTechOp(
  farmId: string,
  seasonId: string,
  farmLandId_fromUrl?: string
): ITechOperationFormData {
  const ret: ITechOperationFormData = {
    id: uuidv4().toUpperCase(),
    farmId,
    seasonId,
    cropTypeId: "", // ??
    startDate: null,
    finishDate: null,
    fieldSize: 0,
    comment: "",
    productionType: ProductionType.default,
    techAssetId: null,
    trailerAssetId: "",
    employeeId: "",
    params: {},
    seedsMAE: null,
    fertilisersMAE: null,
    chemicalsMAE: null,
  };
  if (farmLandId_fromUrl !== undefined) {
    ret.farmLandId = farmLandId_fromUrl;
  }
  return ret;
}
