import {
  FetchAreaHistory_forOpNumberRequest,
  useFetchAreaHistory_forOpNoQuery,
} from "../../store/techop-related.rtkq";
import { devlog } from "../../../../shared/utils/log";
import { ITechOperationDto } from "../../shared/dtos/tech-operation.dto";
import { useEffect } from "react";

export type TechOpListRequest = {
  farmLandId: string;
  Real_farmLandId?: string;
  techOperationGroupId?: string;
  operationNumber: number;
};

export type TechOpListRequestor = {
  isLoading: boolean;
  isFetching: boolean;
  isError: boolean;
  error: unknown;
  fetchedAreaHistory_forOpNo: ITechOperationDto[] | undefined;
};

export function useAreaHistoryRequestor(request: TechOpListRequest): TechOpListRequestor {
  const {
    farmLandId,
    //  Real_farmLandId,
    techOperationGroupId,
    // operationNumber,
  } = request;

  const areaHistory_forOpNumberRequest: FetchAreaHistory_forOpNumberRequest = {
    farmLandId: request.farmLandId,
    Real_farmLandId: request.Real_farmLandId, // TODO: undefined? Is it unused variable?
    operationNumber: request.operationNumber,
    techOperationGroupId: request.techOperationGroupId || "NO_GROUP_ID",
  };

  const valid =
    farmLandId !== "FARM_LAND_ID_NOT_SET" &&
    farmLandId.length > 0 &&
    techOperationGroupId !== undefined;
  // && operationNumber > 0; // left for further options if they ever appear

  const fetchedAreaHistoryData_forOpNoQuery = useFetchAreaHistory_forOpNoQuery(
    areaHistory_forOpNumberRequest,
    {
      skip: !valid,
      refetchOnMountOrArgChange: true,
    }
  );

  const {
    isLoading, // Query is currently loading for the first time. No data yet.
    isFetching, // Query is currently fetching, but might have data from an earlier request.
    isError,
    error,
    data: fetchedAreaHistory_forOpNo,
  } = fetchedAreaHistoryData_forOpNoQuery;

  useEffect(() => {
    isError && devlog(`ERROR useFetchAreaHistory_forOpNoQuery()`, error);
  }, [isError, error]);

  const ret: TechOpListRequestor = {
    isLoading,
    isFetching,
    isError,
    error,
    fetchedAreaHistory_forOpNo: fetchedAreaHistory_forOpNo,
    // fetchedAreaHistory_forOpNo: operationNumber > 0 ? fetchedAreaHistory_forOpNo : [], TODO: If testing is ok, then remove
  };
  return ret;
}
